<template>
  <section class="v-hero-mobile">
    <div class="v-hero-mobile_head">
      <img
        class="v-hero-mobile_logo"
        src="~/assets/img/svg/select-city/logo.svg"
        alt="логотип компании"
      >
      <div
        v-if="currentItem"
        class="v-hero-mobile_city"
      >
        {{ currentItem.title ? currentItem.title : currentItem.city }}
      </div>
    </div>
    <p class="v-hero-mobile_desc">
      С 2005 года строим удобные и <br> надежные дома по новейшим мировым технологиям.
    </p>
    <div class="v-hero-mobile_tab-block">
      <div
        v-for="item in citiesList"
        :key="item.id"
        class="v-hero-mobile_tab-item"
        :class="getClass(item)"
        @click="clickHandler(item)"
      >
        {{ item.city }}
      </div>
    </div>
    <div
      v-if="!currentItem"
      class="v-hero-mobile_choose"
    >
      Выбери город <br>
      и обратись в отдел продаж
    </div>
    <div
      v-if="currentItem"
      class="tab_text-block"
    >
      <h3
        class="tab_title"
        v-html="currentItem.modalTitle"
      ></h3>
      <div
        v-if="currentItem.address"
        class="tab_item"
      >
        <h4 class="tab_item-title">Адрес</h4>
        <p
          class="tab_item-text"
          v-html="currentItem.address"
        ></p>
      </div>
      <div
        v-if="currentItem.time"
        class="tab_item"
      >
        <h4 class="tab_item-title">График работы</h4>
        <p
          v-for="(item, i) in currentItem.time"
          :key="i"
          class="tab_item-text"
          v-html="item"
        ></p>
      </div>
      <div
        v-if="currentItem.phone"
        class="tab_item"
      >
        <h4 class="tab_item-title">Телефон</h4>
        <p
          v-for="(item, index) in currentItem.phone"
          :key="index"
          class="tab_item-text"
        >
          <a :href="`tel: ${item.phone}`">{{ item.phone }}</a>
          <span v-if="item.desc">{{ item.desc }}</span>
        </p>
      </div>
      <div
        v-if="currentItem.email"
        class="tab_item"
      >
        <h4 class="tab_item-title">E-mail</h4>
        <p
          v-for="(item, index) in currentItem.email"
          :key="index"
          class="tab_item-text"
        >
          <a :href="`mailto: ${item.email}`">{{ item.email }}</a>
          <span v-if="item.desc">{{ item.desc }}</span>
        </p>
      </div>
      <a
        v-if="currentItem && currentItem.href"
        :href="currentItem.href"
        class="tab_btn-link"
      >Выбрать квартиру</a>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { CitySelectionStubBlockType } from '~/cms/blocks/CitySelectionStubBlock.vue';
import selectCity from "~/utils/selectCity/selectCity";

const props = defineProps<{
	citiesList: CitySelectionStubBlockType[];
}>();
const currentItem = ref<CitySelectionStubBlockType | null>(null);

const clickHandler = (city: CitySelectionStubBlockType) => {
  currentItem.value = selectCity(city, currentItem.value);
};

const getClass = (item: CitySelectionStubBlockType) => {
  const classes = [];
  classes.push(`city__${item.id}`);
  if (item.isActive) {
    classes.push('__active');
  }
  return classes;
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.v-hero-mobile {
	min-height: 100vh;
	padding: 60px 40px;
	background-color: var(--primary);

	@include media('sm') {
		padding: 30px 20px;
	}
}

.v-hero-mobile_head {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 25px;
}

.v-hero-mobile_logo {
	width: 200px;

	@include media('sm') {
		width: 96px;
		margin-top: -2px;
	}
}

.v-hero-mobile_city {
	position: relative;
	height: 60px;
	padding-left: 20px;
	border-left: 1px solid #FFF;
	font-size: 30px;
	line-height: 1px;
	display: flex;
	align-items: center;
	letter-spacing: 0.07em;
	text-transform: uppercase;
	color: #FFF;

	@include media('sm') {
		height: 30px;
		font-size: 22px;
		padding-left: 10px;
		line-height: 27px;
	}
}

.v-hero-mobile_desc {
	font-size: 20px;
	line-height: 26px;
	width: 450px;
	color: var(--white);
	margin-bottom: 80px;

	@include media('sm') {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 32px;
		width: 100%;
		max-width: 320px;
	}
}

.v-hero-mobile_tab-block {
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
	margin-bottom: 20px;
}

.v-hero-mobile_tab-item {
	background: var(--primary-80);
	border-radius: 50px;
	padding: 10px 40px;
	margin: 0 20px 10px 0;
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
	color: var(--white);
	font-weight: 600;
	transition: background-color 0.4s ease, color 0.4s ease;
	cursor: pointer;
	text-decoration: none;

	&.__active,
	&:hover {
		background: var(--secondary);
		color: var(--primary);
	}

	@include media('sm') {
		padding: 10px 20px;
		margin-right: 16px;
		font-size: 14px;
		line-height: 20px;
	}
}

.v-hero-mobile_choose {
	font-weight: 600;
	font-size: 36px;
	line-height: 42px;
	color: var(--secondary);

	@include media('sm') {
		font-size: 20px;
		margin: 24px 0;
		line-height: 22px;
	}
}

.tab_title {
	font-family: var(--f-second);
	font-weight: 600;
	font-size: 36px;
	line-height: 40px;
	color: #FFC115;

	@include media('sm') {
		font-size: 20px;
		line-height: 22px;
		margin: 24px 0;
	}
}

.tab_item {
	width: 285px;
	margin-top: 24px;
}

.tab_item-title {
	font-weight: 600;
	font-variation-settings: "wght" 600;
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 8px;
	color: var(--c-white);
}

.tab_item-text {
	font-size: 16px;
	line-height: 26px;
	color: var(--c-white);

	& a {
		color: var(--c-white);
	}
}

.tab_btn-link {
	width: 100%;
	max-width: 320px;
	height: 48px;
	background: #FFC115;
	border-radius: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: #3C1D6D;
	margin-top: 40px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
}

.v-hero-mobile_house-btn-block {
	margin-top: 40px;

	& .tab_btn-link {
		margin-top: 0;
		margin-bottom: 16px;
	}
}

</style>
